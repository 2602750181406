import $ from 'jquery';

$(() => {
  const rating = $('.js-rating');
  const ratingStars = rating.find('.star');

  const handleHoverStar = function () {
    const index = $(this).index();
    for (let i = 0; i <= index; i += 1) {
      $(ratingStars[i]).addClass('active');
    }
  };

  const handleBlurStar = function () {
    ratingStars.removeClass('active');
  };

  const handleClickStar = function () {
    const index = $(this).index();
    ratingStars.removeClass('star--half star--full').addClass('star--empty');

    for (let i = 0; i <= index; i += 1) {
      $(ratingStars[i]).removeClass('star--empty').addClass('star--full');
    }

    localStorage.setItem('rating-star', index + 1);
  };

  ratingStars.hover(handleHoverStar, handleBlurStar);
  ratingStars.click(handleClickStar);
});
